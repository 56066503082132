<template>
  <section class="l-content">
    <el-card class="l-condition">
      <el-form :inline="true" :model="condition">
        <el-form-item label="上报时间">
          <l-datepicker type="date" v-model="condition.startDate"></l-datepicker>至
          <l-datepicker type="date" v-model="condition.endDate"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.deviceNo" size="small" placeholder="设备编号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="msgDesc" slot-scope="scope">
          <el-button type="text" @click="viewJSON(scope.row)">{{ scope.row.msgDesc }}</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData" :simple="true"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import VJt808Detail from './jt808/detail'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - this.$heightOffset1 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '设备编号', prop: 'deviceNo' },
          { label: '消息ID', prop: 'msgId' },
          { label: '消息描述', slot: 'msgDesc' },
          { label: 'JSON报文', prop: 'jsonContent', overflow: true },
          { label: '上报报文', prop: 'hexContent', overflow: true },
          { label: '上报时间', prop: 'recordTime' }
        ]
      },
      condition: {
        deviceNo: '',
        startDate: this.$lgh.getDate(),
        endDate: this.$lgh.getDate(),
        pageIndex: 1,
        pageSize: 20
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/platform/jt808/original/list', [this.condition])
    },
    async viewJSON(item) {
      await this.$lgh.openDialog({
        ins: VJt808Detail,
        parent: this,
        title: '报文详细',
        data: {
          dataInfo: item
        }
      })
    }
  }
}
</script>
